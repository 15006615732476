import React, { FunctionComponent } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  pictureCard,
  pictureCardHoverOverlay,
  pictureCardSubLinks,
} from "./index.module.css";

export type Props = {
  pictureObject?: any;
  pictureUrl?: string;
  tagline: string;
  title: string;
  subLinks?: Array<{
    href: string;
    external: boolean;
    icon: JSX.Element;
  }>;
  className?: string;
};

const subLinkClassName = "my-[17px] mx-2 p-2";
const fullSubLinkClassName = "w-full p-6";

const PictureCardHuman: FunctionComponent<Props> = ({
  pictureObject,
  pictureUrl,
  subLinks,
  tagline,
  title,
  className,
}) => (
  <div className={`${className} ${pictureCard} block`}>
    <div className="relative h-52 sm:h-[351px] overflow-hidden">
      {!!pictureObject && (
        <GatsbyImage
          image={getImage(pictureObject) as any}
          alt={title}
          className="w-full h-full object-cover"
        />
      )}
      {!!pictureUrl && (
        <img
          src={pictureUrl}
          alt={title}
          className="w-full h-full object-cover"
        />
      )}

      <div className={pictureCardHoverOverlay} />

      {!!subLinks?.length && (
        <div
          className={`${pictureCardSubLinks} ${
            subLinks.length > 1 && "px-4"
          } absolute z-10 bottom-0 left-0 right-0 flex items-center flex-wrap bg-neon-green translate-y-full transition-all duration-300 ease-in-out`}
        >
          {subLinks.map((link) => (
            <button
              key={link.href}
              className={
                subLinks?.length > 1 ? subLinkClassName : fullSubLinkClassName
              }
              onClick={() => window.open(link.href, "_blank")}
            >
              {link.icon}
            </button>
          ))}
        </div>
      )}
    </div>

    <p className="text-12px lg:text-relaxed text-gothic text-rubik mt-[15px] lg:mt-[25px] mb-[5px]">
      {tagline}
    </p>
    <h3 className="font-bold text-lg lg:text-xl">{title}</h3>
  </div>
);

export default PictureCardHuman;
