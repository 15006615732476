import React, { FunctionComponent } from "react";
import Clips from "./components/Clips";
import { useI18next } from "../../utils/i18n";

export type Props = {};

const Kpi: FunctionComponent<{ number: string; text: string }> = (props) => (
  <h3 className="flex flex-col items-center justify-center last:w-full lg:last:w-auto py-[65px] lg:py-[85px] text-center border-mercury last:border-t lg:last:border-t-0">
    <strong className="font-bold text-7xl">{props.number}</strong>
    <span className="pt-2.5 font-medium text-sm tracking-wide uppercase text-gothic">
      {props.text}
    </span>
  </h3>
);

const AboutNumbers: FunctionComponent<Props> = () => {
  const { t } = useI18next();

  return (
    <section className="relative z-10">
      <Clips />

      <div className="constrained pt-[475px] sm:pt-[370px] pb-12 lg:flex lg:justify-between lg:items-center">
        <div className="lg:pr-8">
          <div className="cubicle bg-neon-green" />
          <h2 className="h1 mt-[45px] lg:mt-12 mb-6 lg:mb-12 lg:max-w-[393px] font-bold md:tracking-wide">
            {t("about.numbers.title")}
          </h2>
          <p className="lg:max-w-[393px] mb-[65px] lg:mb-0 text-lg text-abbey">
            {t("about.numbers.paragraph")}
          </p>
        </div>

        <div className="lg:w-[776px] border-t border-b border-mercury">
          <div className="flex flex-wrap justify-around">
            <Kpi number="90+" text={t("about.numbers.consultants")} />
            <Kpi number="6" text={t("about.numbers.offices")} />
            <Kpi number="300+" text={t("about.numbers.clients")} />
          </div>
          <div className="flex flex-wrap justify-around border-t border-mercury">
            <Kpi number="1K+" text={t("about.numbers.projects")} />
            <Kpi number="30+" text={t("about.numbers.countries")} />
            <Kpi number="100+" text={t("about.numbers.pro-bono")} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutNumbers;
