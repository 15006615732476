import React, { FunctionComponent } from "react";
import illustration from "../../images/PRO BONO.svg";

export type Props = {
  title: string;
  className?: string;
};

const ProBono2: FunctionComponent<Props> = ({ className, title, children }) => (
  <div className={`${className} constrained`}>
    <div className="lg:flex items-center justify-between bg-black py-16 px-7 lg:px-12 rounded-card text-white">
      <div className="lg:mr-12">
        <img src={illustration} alt="" className="mx-auto" />
      </div>
      <div className="mt-12 lg:mt-0 lg:w-[50%] shrink-0">
        <h3 className="h2 mb-6 font-bold tracking-wide">{title}</h3>
        <div className="text-mercury leading-6">{children}</div>
      </div>
    </div>
  </div>
);

export default ProBono2;
