import React from "react";
import { graphql, PageProps } from "gatsby";
import { useI18next } from "../utils/i18n";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Offices from "../components/Offices";
import AboutJumbotron from "../components/AboutJumbotron";
import AboutNumbers from "../components/AboutNumbers";
import AboutTeam from "../components/AboutTeam";
import AboutRaison from "../components/AboutRaison";
import ProBono2 from "../components/ProBono2";

type DataProps = {
  team: {
    edges: Array<{
      node: {
        frontmatter: {
          title: string;
          occupation: string;
          city: string;
          linkedin: string;
          picture: any;
        };
      };
    }>;
  };
};

const AboutPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { t } = useI18next();

  return (
    <Layout>
      <SEO
        title={t("about.meta.title")}
        description={t("about.meta.description")}
      />
      <AboutJumbotron />
      <AboutNumbers />
      <AboutTeam team={data.team.edges.map(({ node }) => node.frontmatter)} />
      <AboutRaison />
      <ProBono2
        title={t("about.pro-bono.title")}
        className="-mb-64 relative z-10"
      >
        <p className="mb-4">{t("about.pro-bono.text1")}</p>
        <p className="mb-4">{t("about.pro-bono.text2")}</p>
        <p>{t("about.pro-bono.text3")}</p>
        <a
          className="btn btn-primary inline-block w-full md:w-auto mt-8"
          href="https://energyaccessbooster.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t("about.pro-bono.energy-access-booster-link")}
        </a>
      </ProBono2>
      <Offices />
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String!, $teammembercount: Int!) {
    team: allMarkdownRemark(
      filter: {
        fields: { lang: { in: [$locale, "en"] } }
        frontmatter: { type: { eq: "team" } }
      }
      sort: {
        order: [ASC]
        fields: [fields___isdefaultLanguage, frontmatter___random]
      }
      limit: $teammembercount
    ) {
      edges {
        node {
          frontmatter {
            title
            occupation
            city
            linkedin
            picture {
              childImageSharp {
                gatsbyImageData(
                  width: 245
                  height: 351
                  quality: 90
                  layout: CONSTRAINED
                  transformOptions: { fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
