import React, { FunctionComponent } from "react";
import { useI18next } from "../../utils/i18n";

export type Props = {};

const RaisonItem: FunctionComponent<{ text: string }> = ({ text }) => (
  <h3 className="pt-[45px] pb-[65px] md:pb-[85px] border-t-2 border-neon-green font-medium text-2xl leading-9">
    {text}
  </h3>
);

const AboutRaison: FunctionComponent<Props> = () => {
  const { t } = useI18next();

  return (
    <section className="constrained py-20">
      <p className="mb-[25px] text-12px font-bold tracking-wide uppercase md:text-center text-roboto">
        {t("about.raison.title")}
      </p>
      <h2 className="h1 max-w-[836px] mx-auto md:text-center font-bold tracking-wide">
        {t("about.raison.tagline")}
      </h2>
      <div className="mt-[85px] md:mt-[128px] max-w-[948px] mx-auto">
        <div className="sm:grid grid-cols-2 md:grid-cols-3 gap-x-[50px] pr-[27px] md:pr-0">
          <RaisonItem text={t("about.raison.text1")} />
          <RaisonItem text={t("about.raison.text2")} />
          <RaisonItem text={t("about.raison.text3")} />
          <RaisonItem text={t("about.raison.text4")} />
          <RaisonItem text={t("about.raison.text5")} />
        </div>
      </div>
    </section>
  );
};

export default AboutRaison;
