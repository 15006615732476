import React, { FunctionComponent } from "react";
import { useI18next } from "../../utils/i18n";
import illustration from "../../images/TEAM.svg";
import Clips from "./components/Clips";

export type Props = {};

const AboutJumbotron: FunctionComponent<Props> = () => {
  const { t } = useI18next();

  return (
    <section className="relative bg-mine-shaft pt-12 pb-24 lg:py-115px">
      <Clips />
      <div className="constrained relative z-20">
        <h1 className="mb-[25px] md:mb-[45px] font-bold text-12px tracking-wide uppercase text-white">
          {t("about.jumbotron.title")}
        </h1>
        <h2 className="h1 relative z-10 lg:w-[48.046%] mb-[25px] md:mb-[45px] text-white font-bold tracking-wide">
          {t("about.jumbotron.tagline")}
        </h2>
        <div className="relative z-10 w-full lg:w-[39.37%] mb-12 text-silver text-lg">
          <p className="mb-4">{t("about.jumbotron.paragraph1")}</p>
          <p className="mb-4">{t("about.jumbotron.paragraph2")}</p>
          <p className="mb-4">{t("about.jumbotron.paragraph3")}</p>
          <p className="mb-4">{t("about.jumbotron.paragraph4")}</p>
          <p>{t("about.jumbotron.paragraph5")}</p>
        </div>
        <img
          src={illustration}
          alt=""
          className="lg:absolute top-0 right-ends w-full lg:w-[493px] lg:pl-24 xl:pl-0 mt-12 lg:mt-8 xl:mr-16"
        />
      </div>
    </section>
  );
};

export default AboutJumbotron;
