import React, { FunctionComponent, useState } from "react";
import LinkedIn from "../Icons/LinkedIn";
import PictureCardHuman from "../PictureCardHuman";
import Clips from "./components/Clips";
import { ROUTES } from "../../constants";
import { useI18next } from "../../utils/i18n";
import { aboutTeamList, aboutTeamListItem } from "./index.module.css";

export type Props = {
  team: Array<{
    title: string;
    occupation: string;
    city: string;
    picture: any;
    linkedin: string;
  }>;
};

const nbOfPeopleToShow = 8;

const AboutTeam: FunctionComponent<Props> = ({ team }) => {
  const { language, t } = useI18next();
  const [showAllTeam, setShowAllTeam] = useState<boolean>(false);

  return (
    <section className="relative z-10 pb-[300px] sm:pb-[370px]">
      <Clips />
      <div className="constrained py-20">
        <div className="md:max-w-[393px] mx-auto mb-[45px] lg:mb-[145px] md:text-center">
          <h2 className="h1 mb-[25px] lg:mb-[45px] font-bold tracking-wide">
            {t("about.team.title")}
          </h2>
          <p className="text-lg text-abbey">{t("about.team.paragraph")}</p>
        </div>

        <div className={aboutTeamList}>
          {team.map((member, index) => {
            if (!showAllTeam && index + 1 > nbOfPeopleToShow) return null;
            return (
              <PictureCardHuman
                key={member.title}
                className={aboutTeamListItem}
                pictureObject={member.picture}
                tagline={`${member.occupation} - ${member.city}`}
                title={member.title}
                subLinks={
                  member.linkedin
                    ? [
                        {
                          href: member.linkedin,
                          external: true,
                          icon: <LinkedIn className="h-4 text-mine-shaft" />,
                        },
                      ]
                    : undefined
                }
              />
            );
          })}
        </div>

        <div className="md:flex justify-center mx-[20px]">
          {team.length > nbOfPeopleToShow && !showAllTeam && (
            <button
              className="btn btn-default w-full md:w-auto mb-[25px] md:px-[85px]"
              onClick={() => setShowAllTeam(true)}
            >
              {t("about.team.meet")}
            </button>
          )}
          <a
            className="btn btn-default-inverted inline-block w-full md:w-auto mb-[25px] md:ml-[25px] md:px-[85px]"
            href={ROUTES.careers.url(language)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("about.team.careers")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutTeam;
